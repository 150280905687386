<template>
  <div
    :class="[
      'relative lg:h-160',
      backgroundColor,
      overflow === 'hidden' ? 'overflow-hidden' : '',
    ]"
  >
    <!-- Arrows on desktop -->
    <svg
      class="hidden lg:block absolute left-[-180px] top-[155px] lg:left-[-100px] lg:-top-[75px]"
      width="550"
      height="550"
      viewBox="0 0 550 550"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        fill-opacity="0.3"
        fill-rule="evenodd"
        stroke="none"
        d="M 277.591187 0 L 550 275 L 277.591187 550 C 238.773193 513.252991 238.773193 453.663971 277.591187 416.900024 L 418.154083 275 L 277.591187 133.100037 C 238.773193 96.335907 238.773193 36.764099 277.591187 0 Z M 29.114031 0 L 301.522186 275 L 29.114031 550 C -9.704649 513.252991 -9.704649 453.663971 29.114031 416.900024 L 169.676743 275 L 29.114031 133.100037 C -9.704649 96.335907 -9.704649 36.764099 29.114031 0 Z"
      />
    </svg>
    <div class="flex flex-col h-full max-w-[1320px] m-auto">
      <div
        :class="[
          'order-2 flex items-center py-20 px-8 lg:w-full min-h-[240px] lg:h-full',
          {
            'lg:max-w-[60%]': !width,
            'lg:max-w-[100%]': width === 'full',
          },
        ]"
      >
        <!-- Arrows on mobile -->
        <svg
          class="lg:hidden absolute left-[-230px] h-full lg:left-[-100px]"
          viewBox="0 0 550 550"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#ffffff"
            fill-opacity="0.3"
            fill-rule="evenodd"
            stroke="none"
            d="M 277.591187 0 L 550 275 L 277.591187 550 C 238.773193 513.252991 238.773193 453.663971 277.591187 416.900024 L 418.154083 275 L 277.591187 133.100037 C 238.773193 96.335907 238.773193 36.764099 277.591187 0 Z M 29.114031 0 L 301.522186 275 L 29.114031 550 C -9.704649 513.252991 -9.704649 453.663971 29.114031 416.900024 L 169.676743 275 L 29.114031 133.100037 C -9.704649 96.335907 -9.704649 36.764099 29.114031 0 Z"
          />
        </svg>
        <div class="relative flex flex-col gap-6 w-full text-white">
          <h1 class="headline">
            <slot name="title" />
          </h1>
          <div v-if="$slots.description" class="text-xl">
            <slot name="description" />
          </div>
          <div v-if="$slots.body" class="w-full z-20">
            <slot name="body"></slot>
          </div>
          <div v-if="$slots.footer" class="w-full z-10">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
      <!-- Side image with optional gradient -->
      <div
        class="order-1 relative lg:absolute h-[220px] lg:top-0 lg:right-0 lg:w-[35%] lg:h-full"
      >
        <slot name="image">
          <NuxtImg class="w-full h-full object-cover" :src="image" />
        </slot>
        <div
          v-if="gradient"
          class="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-black/75"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '/assets/img/ucrs-placeholder.png',
    },

    gradient: {
      type: Boolean,
      default: false,
    },

    preload: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: null,
    },

    backgroundColor: {
      type: String,
      default: null,
    },

    overflow: {
      type: String,
      default: 'hidden',
    },
  },

  head() {
    const { preload } = this

    if (!preload) {
      return
    }

    const { image } = this
    const href = this.$img(image)

    return {
      rel: 'preload',
      as: 'image',
      href,
      fetchpriority: 'high',
    }
  },
}
</script>
